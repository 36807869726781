<template>
    <v-row justify="center" style="z-index: 100000;">
        <v-dialog v-model="dialog" width="20%">
            <v-card title="دخول سريع">
                <v-card-text>
                    <v-list>

                        <v-list-item v-for="(item, i) in items" :key="i" :value="item" @click="login(item)"
                            color="primary" rounded="xl">
                            <template v-slot:prepend>
                                <v-icon :icon="item.icon"></v-icon>
                            </template>

                            <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item>
                    </v-list>

                </v-card-text>


                <v-card-actions>
                    <v-btn color="var(--theme-color)" variant="text" @click="confirm">
                        {{ $t("Close") }}
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script setup>
import { useAuthStore } from "~/lib/features/auth/store/auth_store"

const authStore = useAuthStore()
const nuxt = useNuxtApp()

let dialog = ref(false)

let items = [
    { text: 'EPC Customer', icon: 'mdi-account', email: 'epc_customer@ebkar.ly', pass: 'Asdf@12345' },
    { text: 'EPC Team Member', icon: 'mdi-account', email: 'epc_team_member@ebkar.ly', pass: 'Asdf@12345' },

]

function login(data) {
    confirm()
    authStore.login(data.email, data.pass)
}

function confirm() {
    dialog.value = false;
}

function reject() {
    dialog.value = false;
}

nuxt.$listen("open_quick_login_dialog", () => {
    console.log("open", dialog.value);
    dialog.value = true;
})
</script>